import "./bootstrap-config";
import "./font-awesome-config";
import "../styles/main.scss";

// Uncomment to add Animate on Scroll library
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 1000,
  once: false,
  mirror: true,
});

// Uncomment to add Swiper carousel library
import "./swiper-config";


import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your custom options
});

// Uncomment to add Masonry Layout library
// import Masonry from "masonry-layout";


import "./theme";
