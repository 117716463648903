// Load the base Font Awesome styles
import "@fortawesome/fontawesome-pro/css/fontawesome.min.css";

// Uncomment to load other icon styles as needed
import "@fortawesome/fontawesome-pro/css/regular.min.css";
import "@fortawesome/fontawesome-pro/css/brands.min.css";
// import "@fortawesome/fontawesome-pro/css/light.min.css";
// import "@fortawesome/fontawesome-pro/css/sharp-light.min.css";
// import "@fortawesome/fontawesome-pro/css/sharp-regular.min.css";
// import "@fortawesome/fontawesome-pro/css/sharp-solid.min.css";
// import "@fortawesome/fontawesome-pro/css/sharp-thin.min.css";
import "@fortawesome/fontawesome-pro/css/solid.min.css";
// import "@fortawesome/fontawesome-pro/css/thin.min.css";
